/* Custom List Styles */
.customList ul {
    font-size: 12px;
    list-style-type: disc;
    list-style-position: inside;
  }
  
  .customList ol {
    font-size: 12px;
    list-style-type: decimal;
    list-style-position: inside;
  }
  .customList p {
    font-size: 12px;
  }
  .customList h1,
  .customList h2,
  .customList h3,
  .customList h4,
  .customList h5,
  .customList h6 {
    font-size: 14px;
  }
  
  /* Dark Overlay Styles */
  .dark-overlay h1,
  .dark-overlay h2,
  .dark-overlay h3,
  .dark-overlay h4,
  .dark-overlay h5,
  .dark-overlay h6,
  .dark-overlay p,
  .dark-overlay ul,
  .dark-overlay ol {
    color: white;
    /* Additional styles for dark overlay elements can be added here */
  }
  